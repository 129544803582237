<template>
  <div>
    <b-form-select
      v-model="printer"
      :options="printers"
      size="sm"
      @change="savePrinter"
    ></b-form-select>
  </div>
</template>

<script>
import {
  getLabelPrinter,
  setLabelPrinter,
} from '@/helpers';

export default {
  name: 'PrinterSelect',
  components: {},
  data() {
    return {
      printer: null,
      printers: [],
      loadingCount: 0,
    };
  },
  methods: {
    savePrinter(id) {
      setLabelPrinter(id);
    },
    resetPrinter() {
      const id = this.printers.length > 0 ? this.printers[0].value : null;
      this.printer = id;
      setLabelPrinter(id);
    },
    loadPrinter() {
      const id = getLabelPrinter();
      if (id === null) {
        this.resetPrinter();
        return;
      }
      const isValid = this.printers.find((printer) => printer.value === id);
      if (!isValid) {
        this.resetPrinter();
        return;
      }
      this.printer = id;
    },
    fetchPrinters() {
      this.loadingCount++;
      this.$http
        .get('/label_printers')
        .then((res) => {
          this.printers = res.body.map((printer) => ({
            text: `Printer: ${printer.printer_name}`,
            value: printer.id,
          }));
          this.loadPrinter();
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch printers: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  created() {
    this.fetchPrinters();
  },
};
</script>

<style lang="scss" scoped>
</style>
